import LazyLoad from "react-lazyload";
import GALLERY_IMAGES, { GalleryProps } from "./library";
// import Modal from "./Modal";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  AspectRatio,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import Fading from "components/Fading";
import ImageWebp from "components/ImageWebp";
import { H3 } from "theme/ui-components/Typography";
import { Parallax } from "react-scroll-parallax";

const GalleryItem = ({ filename, caption, effect }: GalleryProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        onClick={onOpen}
        tabIndex={0}
        cursor='pointer'
        aria-label={`click to zoom in ${caption}`}
        position='relative'
        overflow='hidden'
      >
        <Fading duration={1500} triggerOnce>
          <LazyLoad offset={100} height={100} once>
            <AspectRatio ratio={1}>
              <ImageWebp
                imgURI={filename}
                // w="full"
                // objectFit="cover"
                // objectPosition="center"
                alt={caption}
                opacity='0.7'
                transition='ease-in-out 500ms'
                filter={effect ? "grayscale(100%)" : "unset"}
                _hover={{
                  opacity: 1,
                  transform: "scale(1.05)",
                  filter: "unset",
                }}
                // sx={{
                //   "&.bw": {
                //     filter: "grayscale(100%)",
                //   },
                // }}
              />
            </AspectRatio>
            {caption && (
              <Box pos='absolute' bottom={4} left={4}>
                {/* <Parallax translateY={[0, -300]}> */}
                <H3
                  color='white'
                  letterSpacing='0.25em'
                  textTransform='uppercase'
                  fontFamily='body'
                  fontWeight='600'
                  fontSize='xs'
                >
                  {caption}
                </H3>
                {/* </Parallax> */}
              </Box>
            )}
          </LazyLoad>
        </Fading>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
        <ModalOverlay background='rgba(0, 0, 0, 0.7)' />
        <ModalContent bgColor='transparent' m={0}>
          <ModalBody
            onClick={onClose}
            boxShadow='none'
            boxSize='full'
            display='flex'
            justifyContent='center'
            alignItems='center'
            p={0}
            position='relative'
          >
            <ModalCloseButton
              aria-label='Close image modal'
              size='lg'
              color='black'
              borderRadius='full'
              bgColor='white'
              top={{ base: 2, lg: 4 }}
              right={{ base: 2, lg: 2 }}
              sx={{
                marginTop: "3px",
                marginRight: "10px",
                marginBottom: "13px",
                marginLeft: "auto",
              }}
              _hover={{
                bgColor: "grey.100",
              }}
            />
            <Box cursor='zoom-out'>
              <ImageWebp
                imgURI={filename}
                alt={caption}
                //w="full"
                // maxH="100vh"
              />
            </Box>
          </ModalBody>
          {/* <ModalHeader>{caption}</ModalHeader> */}
        </ModalContent>
      </Modal>
    </>
  );
};

const Gallery = () => (
  <Box as='section' className='gallery'>
    {GALLERY_IMAGES.map((data: any) => {
      // format the filename to be used as alt text
      // const value = formatlink(data.filename);
      return (
        <GalleryItem
          key={data.filename}
          filename={data.filename}
          caption={data.caption}
          effect={data.effect}
        />
      );
    })}
  </Box>
);

export default Gallery;
