import React from "react";
import styled from "styled-components";

interface ArrowProps {
  children?: React.ReactNode;
  direction?: string;
  left?: boolean;
  top?: boolean;
}

const StyledArrow = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
  }
  &::after,
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 3px;
    ${(props: any) => {
      if (props.top) {
        return `
        top: 4px;
        left: 6px;
    `;
      }
    }}
  }
  &::after {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    bottom: 7px;
    ${(props: any) => {
      if (props.left) {
        return `
        transform: rotate(45deg);
    `;
      } else if (props.top) {
        return `
        transform: rotate(135deg);
    `;
      } else {
        return `
        left: initial; 
      right:3px;
      transform: rotate(225deg);
       
    `;
      }
    }}
  }
  &::before {
    width: 16px;
    height: 2px;
    bottom: 10px;
    background: currentColor;
    ${(props: any) => {
      if (props.top) {
        return `
          width: 2px;
          height: 16px;
          left: 9px;
    `;
      }
    }}
  }
`;

const ArrowIcon = ({ left = false, top = false }: ArrowProps) => {
  return <StyledArrow icon-role="arrow" left={left} top={top} />;
};
export default ArrowIcon;
