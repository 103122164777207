const container = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1360px",
};

const sizes = {
  container,
};

export default sizes;
