import { Box, Container, Flex } from "@chakra-ui/react";
import { PhotoBlock } from "./PhotoBlock";
import { TextBlock } from "./TextBlock";
import { yearsOfExperience } from "helpers";

export const TextAndImage = () => (
  <Box overflowX='hidden' py={{ lg: 24 }}>
    <Container>
      <Flex
        id='about'
        flexWrap='wrap'
        className='about-me'
        justifyItems={"center"}
      >
        <PhotoBlock
          parallaxMove={77}
          imgURI='/photos/photo-2'
          filter='grayscale(100%)'
          alt="Archie Lee in Lake Tekapo, New Zealand"
          opacity={{ base: 0.87, lg: 1 }}
          transform={{
            base: "translateY(-33%) scale(1.15)",
            lg: "translateX(-50%) scale(1.825)",
          }}
        />
        <TextBlock
          heading='About me'
          bodyContent={
            <>
              <p>
                I'm a coding wizard by day, designer by night. I'm the superhero
                you never knew you needed - a rare hybrid of design and
                development that's sure to knock your socks off!
              </p>
              <p>
                I love to create stunning visuals and bring them to life with my
                trusty coding skills. It's like being an artist and a mad
                scientist all at once! And let's be real - who wouldn't want to
                be a mad scientist?
              </p>
              <p>
                With my ability to blend beauty and brains, I'm here to save the
                day and make your website dreams a reality. So put on your
                seatbelts and get ready for the ride of your life - because with
                me on your side, anything is possible!
              </p>
            </>
          }
        />
      </Flex>
      <Flex
        as='section'
        className='about-me'
        flexWrap='wrap'
        justifyContent={"center"}
        mt={{ base: 6, lg: 64 }}
      >
        <TextBlock
          heading='Simplicity & Creativity'
          bodyContent={
            <>
              <p>
                I'm a digital designer & front-end developer, a dedicated,
                enthusiastic and highly- motivated web professional with over{" "}
                {yearsOfExperience(2009)}{" "}
                years of commercial experience in web design and front-end
                development.
              </p>
              <p>
                As a designer, I truly understand the development process can
                offer expert advice on what's doable and what's not, which can
                open the doors for a lot of creativity and perhaps shut the
                doors on a lot of stuff that seems cool on paper (or in
                wireframes) but really isn't feasible from a construction
                standpoint.
              </p>
              <p>
                As a Front end Developer, I love what I do and have a passion
                for bringing simplicity, elegance, and clarity to the web.
              </p>
            </>
          }
        />
        <PhotoBlock
          imgURI='/photos/IMG_6157'
          alt="Archie Lee in front of Mount Cook, New Zealand"
          display={{ base: "none", lg: "unset" }}
          filter='grayscale(100%)'
          parallaxMove={-33}
          transform={{
            lg: "translateX(33%) scale(1.5)",
          }}
        />
      </Flex>
    </Container>
  </Box>
);
