import { Box, Flex, VStack } from "@chakra-ui/react";
import { useLayoutEffect } from "react";

import {
  Parallax,
  ParallaxProvider,
  useParallaxController,
} from "react-scroll-parallax";
import { Container } from "./components/Container";

import { Footer } from "components/Footer";
import Gallery from "components/Gallery";
import { GoTopButton } from "components/GoTopButton";
import Navigation from "components/Navbar";

import "@fontsource/archivo/300.css";
import "@fontsource/archivo/400.css";
import "@fontsource/archivo/800.css";
import "@fontsource/playfair-display/700.css";
import { Headline } from "theme/ui-components/Typography";
import { TextAndImage } from "./components/TextAndPhoto/TextAndImage";
import Typewriter from "./components/Typewriter";

const ParallaxCache = () => {
  const parallaxController = useParallaxController();

  useLayoutEffect(() => {
    const handler = () => parallaxController?.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);

  return null;
};

const App = () => {
  /* display loading spinner */
  // const [isLoading, setLoading] = useState(true);
  // const fakeRequest = () => {
  //   return new Promise<void>((resolve) => setTimeout(() => resolve(), 1500));
  // };
  // useEffect(() => {
  //   fakeRequest().then(() => {
  //     const el = document.querySelector(".loader-container");
  //     if (el) {
  //       el.classList.add("fade-out");
  //       /* remove the element after 0.5s */
  //       setTimeout(function () {
  //         el.remove();
  //         document.body.classList.remove("no-scroll");
  //         document.body.removeAttribute("class");
  //       }, 500);
  //       setLoading(!isLoading);
  //     }
  //   });
  // }, [isLoading]);

  // if (isLoading) {
  //   return null;
  // }
  return (
    <>
      <ParallaxProvider>
        <ParallaxCache />
        <Box
          as='header'
          bgImage="url('/images/photos/photo-4.webp')"
          bgRepeat='no-repeat'
          bgAttachment='fixed'
          bgSize='cover'
          bgPos='center'
          h={{ base: "100vh" }}
          bgColor='black.400'
          pos='relative'
          _before={{
            content: '""',
            bgColor: "blackAlpha.700",
            boxSize: "full",
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            zIndex: 1,
          }}
        >
          <Navigation zIndex={3} />
          <Flex
            alignItems='center'
            boxSize='full'
            justifyContent='center'
            sx={{
              ".heading-holder": {
                position: "relative",
                zIndex: 2,
              },
            }}
          >
            <Parallax className='heading-holder' translateY={[-100, 100]}>
              <VStack
                spacing={6}
                zIndex={3}
                px={4}
                textAlign='center'
                maxW='container.md'
                color='whiteAlpha.800'
              >
                <Headline color='whiteAlpha.900'>
                  Kia ora, I'm Archie
                  <br />
                  A&nbsp;
                  <Typewriter
                    textArray={[
                      "Digital Designer",
                      "Web Developer",
                      "Photographer",
                    ]}
                  />
                  {/* a designer who codes */}
                </Headline>
                {/* <Text fontSize='18px' lineHeight='1.618' letterSpacing='0.44px'>
                    With a rare blend of creative and technical skills, I create
                    visually stunning designs that are optimized for a seamless
                    user experience. Let's collaborate and bring your vision to
                    life!
                  </Text> */}
              </VStack>
            </Parallax>
          </Flex>
        </Box>
        <TextAndImage />
        <Box as='main' className={"entry-content"}>
          <Container as='section' id='gallery' py={{ base: 12, md: 24 }}>
            <Gallery />
          </Container>
        </Box>
      </ParallaxProvider>
      <Footer />
      <GoTopButton />
    </>
  );
};

export default App;
