import { Box, Text, keyframes } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import EmojiText from "../EmojiText";

const blinking = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

interface Props {
  textArray: string[];
}

export const Typewriter: React.FC<Props> = ({ textArray }) => {
  const [index, setIndex] = useState(0);
  const [typeIndex, setTypeIndex] = useState(0);
  const [isAdding, setIsAdding] = useState(true);

  const typingText = textArray[typeIndex].slice(0, index);

  useEffect(() => {
    const typingTimer = setTimeout(
      () => {
        if (isAdding) {
          if (index < textArray[typeIndex].length) {
            setIndex(index + 1); // if the sentence is not yet fully typed, add a letter
          } else {
            // if the sentence is fully typed, wait for ?? seconds and then start deleting
            setTimeout(() => {
              setIsAdding(false);
            }, 3000); // wait timing
          }
        } else {
          // if the sentence is being deleted
          if (index > 0) {
            setIndex(index - 1); // if the sentence is not yet fully deleted, delete a letter
          } else {
            // if the sentence is fully deleted, wait for ?? seconds and then start adding
            setTimeout(() => {
              setIsAdding(true);
              setTypeIndex((typeIndex + 1) % textArray.length); // go to the next sentence
            }, 300); // wait timing
          }
        }
      },
      isAdding ? 111 : 22 // typing/deleting speed
    );
    return () => clearTimeout(typingTimer);
  }, [index, isAdding, textArray, typeIndex]);

  return (
    <Text
      as='span'
      _after={{
        content: '"_"',
        animation: `${blinking} 1s step-end infinite`,
        ariaLabel: "blinking underscore",
      }}
    >
      {/* <EmojiText
        text={index === 0 ? ":art:" : index === 1 ? ":compurter:" : ":camera:"}
      /> */}
      {typingText}
    </Text>
  );
};
