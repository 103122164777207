import { QuoteIcon } from "../../theme/ui-components/icons";
import MOTTOS from "./mottos.json";
import { Box, Text, keyframes } from "@chakra-ui/react";

const boxSizeH = 100;
const duration = 30;

const moveInOut = keyframes`
  0%, 20%, 100% {
    margin-top: 0;
  }

  21%, 40% {
    margin-top: -${boxSizeH}px;
  }

  41%, 60% {
    margin-top: -${boxSizeH * 2}px;
  }

  61%, 80% {
    margin-top: -${boxSizeH * 3}px;
  }

  81%, 99% {
    margin-top: -${boxSizeH * 4}px;
  }
`;

const Mottos = () => {
  return (
    <Box height={`${boxSizeH}px`} overflow='hidden' position='relative'>
      <QuoteIcon pos='absolute' left={0} top={1} />
      {MOTTOS.map((motto: string, index: number) => {
        // console.log(MOTTOS.length);
        return (
          <Box
            key={index}
            className={`item slider-text-${index}`}
            height={`${boxSizeH}px`}
          >
            <Text
              as='blockquote'
              margin={0}
              px={6}
              sx={{
                animation:
                  index === 0 ? `${moveInOut} infinite ${duration}s` : "unset",
                animationDelay:
                  index === 0 ? `${duration / MOTTOS.length}s` : 0,
              }}
            >
              {motto}
              <QuoteIcon
                boxSize={4}
                ml={2}
                color='white'
                // transform='scaleY(-1)'
              />
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};

export default Mottos;
