const colors = {
  transparent: "transparent",
  grey: {
    100: "#D7D7D7",
    200: "#9ca3af",
    300: "#757575",
    400: "#4d4d4d",
    500: "#6A7587",
    600: "#4C5662",
  },
  black: {
    400: "#212121",
    500: "rgb(24, 24, 24)",
  },
  skyblue: {
    400: "#11ddff",
  },
  deepblue: {
    10: "#F8F8F9",
    50: "#F3F4F5",
    100: "#E6E8EB",
    200: "#CDD1D7",
    300: "#6A7587",
    400: "#EEF4F4",
    500: "#071937",
    600: "#040F20",
  },
  red: {
    50: "#FFF4F6",
    400: "#DF3550",
  },
  orange: {
    50: "#FCEEEA",
    400: "#ff6633",
    500: "#DF532D",
    600: "#D24620",
  },
  green: {
    400: "#6EC248",
    500: "#2fa84f",
  },
  teal: {
    100: "#EEF4F4",
    200: "#DDEAEA",
    300: "#90D0D0",
    500: "#22a2a2",
    600: "#209898",
    700: "#1E8D8D",
  },
};
const aliases = {
  surfaceBlue: colors.teal[500],
  deepWaterBlue: colors.deepblue[500],
  westCoastSand: colors.grey[600],
  ManapouOrange: colors.orange[500],
  primary: colors.skyblue[400],
  secondary: colors.deepblue[500],
  tertiary: colors.orange[500],
  success: colors.green[400],
  error: colors.orange[500],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...colors,
  ...aliases,
};
