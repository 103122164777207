const SITE_CONFIG = {
  SITE_NAME: process.env.REACT_APP_TITLE,
  SITE_ROOT_PATH: "/",
  CONTACT_NUMBER: process.env.REACT_APP_NUMBER,
  EMAIL: "hello@archie.kiwi",
  ARCHIEWEB: {
    name: "ArchieWeb",
    url: "https://archieweb.nz",
    service: "digital design, web development",
  },
  RECAPTCHA_SITE_KEY: "",
  RECAPTCHA_SECRET_KEY: "",
  RECAPTCHA_TEST_SITE_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  RECAPTCHA_TEST_SECRET_KEY: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  EMAILJS_serviceID: "service_id_emailjs",
  EMAILJS_templateID: "template_id_emailjs",
  // where it says API Keys
  EMAILJS_userID: "",
};

export default SITE_CONFIG;
