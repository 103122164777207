import { Box, List, ListItem, VStack, Link } from "@chakra-ui/react";
import Mottos from "../Mottos";

import { H3 } from "../../theme/ui-components/Typography";
import { Container } from "../Container";
import { Linkedin, Instagram } from "theme/ui-components/icons";

export const Footer = ({ children }: any) => (
  <Box id='contact' data-testid='footer' as='footer' color='white'>
    <Container className='grid' mb={9}>
      <div>
        <H3>Contact</H3>
        <VStack alignItems='left' spacing={4}>
          <List spacing={4}>
            <ListItem>
              Email:{" "}
              <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                {process.env.REACT_APP_EMAIL}
              </a>
            </ListItem>

            <ListItem
              display={"flex"}
              gap={3}
              sx={{
                a: {
                  color: "whiteAlpha.700",
                  _hover: {
                    color: "white",
                  },
                },
              }}
            >
              <Link href='https://www.linkedin.com/in/archilee/' aria-label='linkedin' isExternal>
                <Linkedin boxSize={6} />
              </Link>
              <Link href='https://www.instagram.com/archi0007/' aria-label='Instagram' isExternal>
                <Instagram boxSize={6} />
              </Link>
            </ListItem>
          </List>
        </VStack>
      </div>
      <div>
        <H3>Connect</H3>
        <p>
          Looking to create some magic together? My inbox is always open, and
          I'm eager to hear about your potential projects or even just chat over
          a cup of tea or coffee (your choice!). As a Christchurch local, I'm
          always up for meeting new people and exploring exciting collaboration
          opportunities. Let's connect and see what kind of creative wonders we
          can conjure up!
        </p>
      </div>
      <div>
        <h3>My mottos</h3>
        <Mottos />
      </div>
      {/* <div>
        <H3>Disclaimer</H3>
        <Box as='p' className='disclaimer' opacity={0.54}>
          Content Disclaimer: All photos and concepts displayed on this site are
          protected by international law. Any unauthorized commercial use,
          whether in part or in whole, is strictly prohibited and requires prior
          written approval as per copyright law. This includes copying,
          distributing, or saving in electronic systems, as well as copying the
          structure, concept, or translation of any content. Please note that we
          are not liable for any damages resulting from linking to or using this
          site.
        </Box>
      </div> */}
    </Container>
    <Box
      className='copyright'
      fontSize={"0.725em"}
      textAlign='center'
      textTransform='uppercase'
      letterSpacing='1.618px'
    >
      <Box as='span' opacity={0.7}>
        All content &copy; {new Date().getFullYear()}
        <i className={"divider"}></i>
      </Box>
      {/* <a
        href="https://archieweb.nz"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="ArchieWeb, a hybrid web designer and developer"
      >
        ArchieWeb
      </a> */}
      {children}
    </Box>
  </Box>
);
