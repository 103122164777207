import { Flex, Box, FlexProps, HStack, Link } from "@chakra-ui/react";
import MENUITEMS from "./navitems.json";

interface NavProps extends FlexProps {}

const Navigation = ({ ...props }: NavProps) => {
  return (
    <Flex
      as="nav"
      w="full"
      position="absolute"
      pt={6}
      justifyContent="center"
      {...props}
    >
      <HStack spacing={{ base: 5, lg: 8 }}>
        {MENUITEMS.map((value: any) => (
          <Box key={value.menuItem}>
            <Link
              color="white"
              textTransform="uppercase"
              textDecor="none"
              fontSize="12px"
              fontWeight="600"
              letterSpacing="2.75px"
              href={value.path}
              aria-label={`scroll to ${value.menuItem}`}
            >
              {value.menuItem}
            </Link>
          </Box>
        ))}
      </HStack>
    </Flex>
  );
};
export default Navigation;
