import { AspectRatio, Box } from "@chakra-ui/react";
import ImageWebp, { WebpProps } from "components/ImageWebp";
import { ElementOrSelector, animate, inView } from "motion";
import { useEffect, useLayoutEffect, useRef } from "react";
import LazyLoad from "react-lazyload";
import { Parallax } from "react-scroll-parallax";

interface Props extends WebpProps {
  parallaxMove: number;
}

export const PhotoBlock = ({ parallaxMove, ...rest }: Props) => {
  const imageHolderRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    if (!imageHolderRef.current) return;

    inView(imageHolderRef.current, ({ target }) => {
      animate(
        imageHolderRef.current as ElementOrSelector,
        { opacity: 1, transform: "none" },
        { delay: 0.3, duration: 2, easing: [0.17, 0.55, 0.55, 1] }
      );
    });
  }, []);

  return (
    <Box
      width={{ lg: "50%" }}
      className='image-holder'
      opacity={0.5}
      ref={imageHolderRef}
    >
      <Parallax translateY={[parallaxMove,25]}>
        {/* <LazyLoad offset={100}> */}
        {/* <AspectRatio ratio={1 / 1}> */}
        <ImageWebp useLazyLoad {...rest} />
        {/* </AspectRatio> */}
        {/* </LazyLoad> */}
      </Parallax>
    </Box>
  );
};
