import { Box, Image, ImageProps } from "@chakra-ui/react";
import LazyLoad from "react-lazyload";
import SITE_CONFIG from "../../siteConfig";

export interface WebpProps extends ImageProps {
  imgURI: string;
  isPNG?: boolean;
  useLazyLoad?: boolean;
  alt?: string;
}

const ImageWebp = ({
  imgURI,
  alt = `${imgURI} - ${SITE_CONFIG.SITE_NAME}`,
  isPNG = false,
  useLazyLoad,
  ...props
}: WebpProps) => {
  const ImageBox = () => (
    <Image
      src={`/images/${imgURI}${isPNG ? ".png" : ".webp"}`}
      fallbackSrc={`/images/${imgURI}.jpg`}
      alt={alt}
      {...props}
    />
  );
  return (
    <Box as='picture'>
      {useLazyLoad ? (
        <LazyLoad height={200} once>
          <ImageBox />
        </LazyLoad>
      ) : (
        <ImageBox />
      )}
    </Box>
  );
};

export default ImageWebp;
