export interface GalleryProps {
  filename: string;
  caption: string;
  effect?: boolean;
}

const GALLERY: GalleryProps[] = [
  // {
  //   filename: "works/sites-bamboo",
  //   caption: "bamboo",
  // },
  // {
  //   filename: "works/sites-finapp_",
  //   caption: "Finance Central",
  // },
  // {
  //   filename: "works/sites-brcars",
  //   caption: "BR Cars",
  // },
  // {
  //   filename: "works/sites-camperlife",
  //   caption: "Camper Life",
  // },
  // {
  //   filename: "works/demo-finapp",
  //   caption: "Finace App",
  // },
  // {
  //   filename: "works/sites-opes",
  //   caption: "OPES - My Wealth Plan",
  // },
  // {
  //   filename: "works/sites-eat",
  //   caption: "Eat",
  // },
  // {
  //   filename: "works/demo-mitsubishi",
  //   caption: "mitsubishi",
  // },
  // {
  //   filename: "works/demo-opes",
  //   caption: "OPES",
  // },
  // photography photos
  {
    filename: "photos/disneyland",
    caption: "Tokyo Disneyland",
  },
  {
    filename: "photos/japan01",
    effect: true,
    caption: "Tokyo, Japan",
  },
  {
    filename: "photos/mt-eden",
    caption: "Mt Eden, Auckland",
  },
  {
    filename: "photos/devenport",
    caption: "devenport, Auckland",
  },
  {
    filename: "photos/lupin",
    caption: "Lupins, Lake Tekapo",
  },
  {
    filename: "photos/shiga",
    caption: "Shiga Japan",
  },
  {
    filename: "photos/kyoto",
    caption: "Kyoto, Japan",
  },
  {
    filename: "photos/photo-4",
    caption: "mahurangi west, Auckland",
    effect: true,
  },
  {
    filename: "photos/hinemoa",
    caption: "Hinemoa",
    effect: true,
  },
  {
    filename: "photos/rapaki",
    caption: "rapaki, Christchurch",
  },
  {
    filename: "photos/mt",
    caption: "Mt Maunganui, Tauranga",
    effect: true,
  },
  {
    filename: "photos/alpaca",
    caption: "Alpaca, mahurangi west",
  },
  // {
  //   filename: "photos/taipei",
  //   caption: "Taipei 101",
  //   effect: "bw",
  // },
];

export default GALLERY;
