import { Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import ArrowIcon from "../Icons/ArrowIcon";

export const GoTopButton = () => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        window.pageYOffset || document.documentElement.scrollTop;
      setActive(scrollHeight > 1800);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      as='button'
      display='flex'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      transition='all 0.33s'
      position='fixed'
      bottom={{ base: "1.25em", md: 4 }}
      right={{ base: "1em", md: 4 }}
      boxSize={{ base: 12, lg: 16 }}
      fontSize='sm'
      zIndex={99}
      border='none'
      outline='none'
      color='white'
      cursor='pointer'
      borderRadius='full'
      opacity={isActive ? 0.7 : 0}
      onClick={scrollTop}
      title='go to top button'
      aria-label='scroll to top'
      _hover={{
        bgColor: "blackAlpha.900",
      }}
    >
      <ArrowIcon top />
    </Box>
  );
};
