import { Container as ChakraContainer, ContainerProps } from "@chakra-ui/react";

export interface Props extends ContainerProps {
  flush?: boolean;
}

export const Container = ({ children, ...rest }: Props) => (
  <ChakraContainer data-testid='custom-container' position='relative' {...rest}>
    {children}
  </ChakraContainer>
);
