import { Box, BoxProps, VStack } from "@chakra-ui/react";
import { animate, inView, stagger } from "motion";
import React, { useEffect, useRef } from "react";

interface TextBlockProps extends BoxProps {
  heading?: string;
  bodyContent: React.ReactNode;
  children?: React.ReactNode;
}

export const TextBlock = ({
  heading,
  children,
  bodyContent,
  ...rest
}: TextBlockProps) => {
  const textHolderRef = useRef(null);

  useEffect(() => {
    // const childElements = React.Children.toArray(children);
    // console.log(childElements);

    if (textHolderRef.current) {
      const element = textHolderRef.current as HTMLElement;

      inView(element, () => {
        animate(
          element.querySelectorAll("*"),
          { opacity: 1 },
          { delay: stagger(0.5), duration: 1.5 }
        );
      });
    }
  }, []);

  return (
    <Box
      ref={textHolderRef}
      position={"relative"}
      zIndex={2}
      px={{ base: 4 }}
      w={{ lg: "50%" }}
      color='white'
      {...rest}
      sx={{
        "*": {
          opacity: 0,
        },
      }}
    >
      {heading && <h2 className='h2'>{heading}</h2>}
      <VStack
        gap={2}
        lineHeight={1.88}
        letterSpacing={"0.33px"}
        color='whiteAlpha.700'
      >
        {bodyContent}
      </VStack>
      {children}
    </Box>
  );
};
