import { createIcon } from "@chakra-ui/react";

const Linkedin = createIcon({
  displayName: "Linkedin",
  viewBox: "0 0 28 28",
  d: "M26 0H1.937C.875 0 0 .938 0 2.063V26c0 1.125.875 2 1.938 2H26c1.063 0 2-.875 2-2V2.062C28 .938 27.062 0 26 0ZM8.437 24H4.314V10.687h4.125V24ZM6.376 8.812C5 8.813 3.937 7.75 3.937 6.438A2.44 2.44 0 0 1 6.376 4C7.688 4 8.75 5.125 8.75 6.438a2.374 2.374 0 0 1-2.375 2.375ZM24 24h-4.188v-6.5c0-1.5 0-3.5-2.125-3.5-2.187 0-2.5 1.688-2.5 3.438V24h-4.124V10.687H15V12.5h.063C15.624 11.437 17 10.312 19 10.312c4.188 0 5 2.813 5 6.376V24Z",
  // path: (
  //   <>
  //     <path
  //       fill="currentColor"
  //       d="M15 6c5 0 9 4 9 9s-4 9-9 9-9-4-9-9 4-9 9-9m0-1C9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10S20.5 5 15 5z"
  //     />
  //   </>
  // ),
});

export default Linkedin;
