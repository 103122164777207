// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      backgroundColor: "black.500",
      "&.chakra-ui-dark": {
        background: "gray.900",
      },
      lineHeight: 1.5,
    },
  },
};
